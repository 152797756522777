import React from "react"
import {graphql} from "gatsby"
import {FormattedMessage, injectIntl, Link, useIntl} from "gatsby-plugin-react-intl"
import Parser from "html-react-parser"

import Layout from "../components/layout"

const ConsultingPage = ({data}) => {
    const intl = useIntl()
    const lang = intl.locale
    var pubs = ""
    if (lang === "ru") {
        pubs = data.ru_publications.edges;
    } else if (lang === "en") {
        pubs = data.en_publications.edges;
    }
    return (
        <Layout>
            {pubs.map(({node}) => {
                return (
                    <div>{Parser(node.html)}</div>
                )
            })}
        </Layout>
    )
}

export default injectIntl(ConsultingPage)

export const query = graphql`
    query{
        ru_publications: allMarkdownRemark(filter: {frontmatter: {content_type: {eq: "consulting"}, published: {ne: false}, language: {eq: "ru"}}}){
            edges{
                node{
                    html
                    frontmatter{
                        title
                    }
                }
            }}

        en_publications: allMarkdownRemark(filter: {frontmatter: {content_type: {eq: "consulting"}, published: {ne: false}, language: {eq: "en"}}}){
            edges{
                node{
                    html
                    frontmatter{
                        title
                    }
                }
            }}
    }
`